export const ICONS = {
	walkAndAccessibilityIcons: [
		{ fileName: "walking_transparent.svg", css: "walking", alt: "walking" },
		{
			fileName: "accessibility.svg",
			css: "accessibility",
			alt: "accessibility"
		}
	],
	walkIcon: [
		{ fileName: "walking_transparent.svg", css: "walking", alt: "walking" }
	],
	accesibilitySmallIcon: [
		{
			fileName: "wheelchair.svg",
			css: "accessibility-xs",
			alt: "accessibility"
		}
	],
	wheelchairIcon: [
		{ fileName: "wheelchair.svg", css: "wheelchair", alt: "wheelchair" }
	]
};

export const INTERVAL_TYPE = {
	STATIC: 0,
	DYNAMIC: 1
};

export const ROUTE_TYPE = {
	TRAIN: 0,
	TRAM: 1
};
