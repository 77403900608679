import React, { Component } from "react";
import TimeDistanceInfo from "../../components/TimeDistanceInfo";
import Badge from "../../components/Badge";
import PollingService from "../../services/PollingService";
import { ICONS, INTERVAL_TYPE, ROUTE_TYPE } from "../../constants";
import ApiParams from "../../models/ApiParams";
import PollingRequest from "../../models/PollingRequest";
import UtilityService from "../../services/UtilityService";
import qs from "query-string";
import Hotkeys from "react-hot-keys";
import { PoseGroup } from "react-pose";

const STATIC_POLLING_INTERVAL = process.env.REACT_APP_STATIC_POLLING_INTERVAL;
const DYNAMIC_POLLING_INTERVAL = process.env.REACT_APP_DYNAMIC_POLLING_INTERVAL;
const JOLLIMONT_STOP_ID = process.env.REACT_APP_JOLLIMONT_STOP_ID;
const TRAM_70_STOP_ID = process.env.REACT_APP_TRAM70_STOP_ID;
const TRAM_70_TO_CITY = process.env.REACT_APP_TRAM70_TO_CITY_DIRECTION_ID;
const TRAM_70_TO_RICHMOND = process.env.REACT_APP_TRAM70_TO_RICHMOND;
const REACT_APP_BASE_DISTANCE_TO_FLINDERS = parseInt(
	process.env.REACT_APP_BASE_DISTANCE_TO_FLINDERS
);
const REACT_APP_BASE_DISTANCE_TO_JOLIMONT = parseInt(
	process.env.REACT_APP_BASE_DISTANCE_TO_JOLIMONT
);
const REACT_APP_BASE_DISTANCE_TO_TRAM_70 = parseInt(
	process.env.REACT_APP_BASE_DISTANCE_TO_TRAM_70
);

class MCGScreen extends Component {
	state = {
		JollimontTrainTimings: [],
		Tram70ToCity: [],
		Tram70ToRichmond: [],
		doors: null,
		alternativeDoors: null,
		staticTimer: null,
		dynamicTimer: null
	};

	componentDidMount() {
		let parameters = qs.parse(this.props.location.search);
		let distanceToExit = parseInt(parameters.distanceToExit);

		this.setState({
			doors: parameters.doors,
			alternativeDoors: parameters.alternativeDoors,
			distanceToFlinders: REACT_APP_BASE_DISTANCE_TO_FLINDERS + distanceToExit,
			distanceToJolimont: REACT_APP_BASE_DISTANCE_TO_JOLIMONT + distanceToExit,
			distanceToTram70: REACT_APP_BASE_DISTANCE_TO_TRAM_70 + distanceToExit,
			distanceToExit: distanceToExit
		}, () => {
			PollingService.init([
				new ApiParams(ROUTE_TYPE.TRAIN, JOLLIMONT_STOP_ID),
				new ApiParams(ROUTE_TYPE.TRAM, TRAM_70_STOP_ID)
			]);

			PollingService.poll(
				new PollingRequest(INTERVAL_TYPE.DYNAMIC, this.getBaseTimes().tram, this.getBaseTimes().train)
			).then(result => {
				this.setState({
					staticTimer: setInterval(() => {
						this.invokePolling(INTERVAL_TYPE.STATIC);
					}, STATIC_POLLING_INTERVAL),
					dynamicTimer: setInterval(() => {
						this.invokePolling(INTERVAL_TYPE.DYNAMIC);
					}, DYNAMIC_POLLING_INTERVAL)
				});
			});
		});
	}

	getBaseTimes = () => { 
		let dateFromUtcTrain = UtilityService.getCurrentTimeStringRelative(
			UtilityService.calculateTimeToArrive(
				this.state.distanceToJolimont,
				this.state.distanceToExit
			)
		);

		let dateFromUtcTram = UtilityService.getCurrentTimeStringRelative(
			UtilityService.calculateTimeToArrive(
				this.state.distanceToTram70,
				this.state.distanceToExit
			)
		);
		
		return { 
			train: dateFromUtcTrain,
			tram: dateFromUtcTram
		};
	}

	invokePolling(intervalType) {

		PollingService.poll(new PollingRequest(intervalType, this.getBaseTimes().tram, this.getBaseTimes().train)).then(
			result => {
				let tram70ToCity =
					result && result.data && result.data[TRAM_70_TO_CITY]
						? result.data[TRAM_70_TO_CITY]
						: [];

				let tram70ToRichmond =
					result && result.data && result.data[TRAM_70_TO_RICHMOND]
						? result.data[TRAM_70_TO_RICHMOND]
						: [];

				let jollimontData =
					result && result.data && result.data[0] ? result.data[0] : [];

				this.setState({
					JollimontTrainTimings: jollimontData,
					Tram70ToCity: tram70ToCity,
					Tram70ToRichmond: tram70ToRichmond
				});

				this.highlightNextService();
			}
		);
	}

	isNextService = (serviceList, runId) => {
		var run = serviceList.filter(item => item.runId === runId);
		if (run.length === 0) return false;
		return run[0].isNextService === true;
	};

	highlightNextService = () => {
		this.setState(prevState => {
			var j = [...prevState.JollimontTrainTimings];
			var tc = [...prevState.Tram70ToCity];
			var tr = [...prevState.Tram70ToRichmond];

			j.forEach(item => (item.isNextService = false));
			tc.forEach(item => (item.isNextService = false));
			tr.forEach(item => (item.isNextService = false));

			j
				.filter(
					item => item.estimatedScheduledTime.isSame(j[0].estimatedScheduledTime)
				)
				.forEach(item => (item.isNextService = true));
			tc
				.filter(
					item => item.estimatedScheduledTime.isSame(tc[0].estimatedScheduledTime)
				)
				.forEach(item => (item.isNextService = true));
			tr
				.filter(
					item => item.estimatedScheduledTime.isSame(tr[0].estimatedScheduledTime)
				)
				.forEach(item => (item.isNextService = true));

			return {
				JollimontTrainTimings: j,
				Tram70ToCity: tc,
				Tram70ToRichmond: tr
			};
		});
	};

	removeService = () => {
		clearInterval(this.state.staticTimer);
		clearInterval(this.state.dynamicTimer);

		this.setState(prevState => ({
			JollimontTrainTimings: prevState.JollimontTrainTimings.slice(1),
			Tram70ToCity: prevState.Tram70ToCity.slice(1),
			Tram70ToRichmond: prevState.Tram70ToRichmond.slice(1)
		}));
	};

	shuffleServices = () => {
		this.setState(prevState => ({
			JollimontTrainTimings: UtilityService.shuffle(
				prevState.JollimontTrainTimings
			),
			Tram70ToCity: UtilityService.shuffle(prevState.Tram70ToCity),
			Tram70ToRichmond: UtilityService.shuffle(prevState.Tram70ToRichmond)
		}));

		this.setState(prevState => {
			var j = [...prevState.JollimontTrainTimings];
			var tc = [...prevState.Tram70ToCity];
			var tr = [...prevState.Tram70ToRichmond];

			j[0].lowFloor = true;
			tc[0].lowFloor = true;
			tr[0].lowFloor = true;

			return {
				JollimontTrainTimings: j,
				Tram70ToCity: tc,
				Tram70ToRichmond: tr
			};
		});
	};

	render() {
		let jollimontTrainBadges = this.state.JollimontTrainTimings
			? this.state.JollimontTrainTimings.map((timing, id) => {
					let arrivalTime = UtilityService.relativeTimeTo(
						timing.estimatedScheduledTime
					);
					let firstOfService = this.isNextService(
						this.state.JollimontTrainTimings,
						timing.runId
					);
					let outerCss = firstOfService ? "bg-white" : "";

					return (
						<Badge
							key={`j-badge-${timing.runId}`}
							hasBorder={!firstOfService}
							activeOuterCss={outerCss}
						>
							<div className="main">{timing.directionName}</div>
							<div className="caption dark-grey">
								<span className="time">{arrivalTime}</span>
								{timing.platformNumber
									? " | Platform " + timing.platformNumber
									: null}
							</div>
						</Badge>
					);
			  })
			: [];

		let tram70ToCityBadges = this.state.Tram70ToCity
			? this.state.Tram70ToCity.map((timing, index) => {
					let arrivalTime = UtilityService.relativeTimeTo(
						timing.estimatedScheduledTime
					);
					let outerCss = this.isNextService(
						this.state.Tram70ToCity,
						timing.runId
					)
						? "bg-white"
						: "";

					return (
						<Badge
							key={`tc-badge-${timing.runId}`}
							icons={ICONS.accesibilitySmallIcon}
							hasIcons={timing.lowFloor}
							hasBorder={
								!this.isNextService(this.state.Tram70ToCity, timing.runId)
							}
							activeOuterCss={outerCss}
							iconCss={timing.lowFloor && timing.isNextService ? "bg-blue" : ""}
						>
							<div className="caption dark-grey">{arrivalTime}</div>
						</Badge>
					);
			  })
			: null;

		let tram70ToRichmondBadges = this.state.Tram70ToRichmond
			? this.state.Tram70ToRichmond.map((timing, index) => {
					let arrivalTime = UtilityService.relativeTimeTo(
						timing.estimatedScheduledTime
					);

					let outerCss = this.isNextService(
						this.state.Tram70ToRichmond,
						timing.runId
					)
						? "bg-white"
						: "";

					return (
						<Badge
							key={`tc-badge-${timing.runId}`}
							icons={ICONS.accesibilitySmallIcon}
							hasIcons={timing.lowFloor}
							hasBorder={
								!this.isNextService(this.state.Tram70ToCity, timing.runId)
							}
							activeOuterCss={outerCss}
							iconCss={timing.lowFloor && timing.isNextService ? "bg-blue" : ""}
						>
							<div className="caption dark-grey">{arrivalTime}</div>
						</Badge>
					);
			  })
			: null;

		return (
			<div className="bp-container">
				<Hotkeys keyName="shift+c" onKeyUp={this.removeService} />
				<Hotkeys keyName="shift+s" onKeyUp={this.shuffleServices} />
				<div className="bp-side-panel">
					<div className="large-arrow-base">
						<img src="/assets/images/up.png" className="up" alt="up" />
					</div>
				</div>
				<div className="bp-right">
					<div className="bp-row row-1 header-row">
						<div className="header-segment">
							<div className="header">
								<span className="header-name">MCG/City</span>
								<span className="header-exit">Exit</span>
							</div>
							<div className="sub-header">
								<span>At {UtilityService.singularOrPlural(this.state.doors, "door")} {this.state.doors}</span>
							</div>
						</div>
						<TimeDistanceInfo
							icons={ICONS.walkAndAccessibilityIcons}
							distance={this.state.distanceToExit}
							exitDistance={this.state.distanceToExit}
						/>
					</div>
				
					<div className="bp-row row-2">
						<div className="info-segment">
							<div className="info-segment-icon">
								<img
									src="/assets/images/train.svg"
									className="train"
									alt="train"
								/>
							</div>
							<div className="info-segment-text">
								<div className="title">Flinders Street Station</div>
								<div className="description">Turn left via Tanderrum Bridge</div>
							</div>
						</div>
						<TimeDistanceInfo
							icons={ICONS.walkAndAccessibilityIcons}
							distance={this.state.distanceToFlinders}
							exitDistance={this.state.distanceToExit}
						/>
					</div>
					<div className="bp-row row-3">
						<div className="left-segment">
							<div className="info-panel">
								<div className="info-segment">
									<div className="info-segment-icon">
										<img
											src="/assets/images/tram.svg"
											className="tram"
											alt="tram"
										/>
									</div>
									<div className="info-segment-text">
										<div className="title">Tram 70</div>
										<div className="description">Via Garden Square</div>
									</div>
								</div>
								<TimeDistanceInfo
									icons={ICONS.walkIcon}
									distance={this.state.distanceToTram70}
									exitDistance={this.state.distanceToExit}
								/>
							</div>
							<div className="timing-panel">
								{this.state.Tram70ToCity != null &&
									this.state.Tram70ToCity.length > 0 && (
										<div className="left-segment">
											<div className="info-header">
												<img
													src="/assets/images/left.png"
													className="left"
													alt="left-arrow"
												/>
												<span className="direction-label">To City</span>
											</div>
											<div className="tram-arrival-info wrap-overflow">
												<PoseGroup
													animateOnMount={false}
													onRest={this.highlightNextService}
												>
													{tram70ToCityBadges}
												</PoseGroup>
											</div>
										</div>
									)}

								{this.state.Tram70ToRichmond != null &&
									this.state.Tram70ToRichmond.length > 0 && (
										<div className="right-segment">
											<div className="info-header">
												<span className="direction-label">To Richmond</span>
												<img
													src="/assets/images/right.svg"
													className="right"
													alt="right-arrow"
												/>
											</div>
											<div className="tram-arrival-info wrap-overflow">
												<PoseGroup
													animateOnMount={false}
													onRest={this.highlightNextService}
												>
													{tram70ToRichmondBadges}
												</PoseGroup>
											</div>
										</div>
									)}
							</div>
						</div>
						<div className="right-segment">
							<div className="info-panel">
								<div className="info-segment">
									<div className="info-segment-icon">
										<img
											src="/assets/images/train.svg"
											className="train"
											alt="train"
										/>
									</div>
									<div className="info-segment-text">
										<div className="title">Jolimont Station</div>
										<div className="description">Via MCG Foot Bridge</div>
									</div>
								</div>
								<TimeDistanceInfo
									icons={ICONS.walkIcon}
									distance={this.state.distanceToJolimont}
									exitDistance={this.state.distanceToExit}
								/>
							</div>
							<div className="train-arrival-panel wrap-overflow">
								<PoseGroup
									animateOnMount={false}
									onRest={this.highlightNextService}
								>
									{jollimontTrainBadges}
								</PoseGroup>
							</div>
						</div>
					</div>
				</div>
				<div className="footer bp-footer">
					<span className="turn-back-icon">
						<img
							src="/assets/images/turn-back-arrow.png"
							alt="u-turn"
							className="turn-back-arrow"
						/>
					</span>
					<span className="direction-block">
						<div className="direction">Turn back for</div>
						<div className="direction-destination">
							<span>Olympic Blvd </span>
							<span>Exit</span>
						</div>
						<div className="direction-exit">
							At {UtilityService.singularOrPlural(this.state.alternativeDoors, "door")} {this.state.alternativeDoors}
						</div>
					</span>
					<span className="icon-with-text">
						<div className="icon-set">
						
						<img src="/assets/images/train.svg" className="train" alt="train" />
						<span className="icon-text">Richmond Station</span>
						</div>
					</span>
					<span className="icon-with-text">
					<div className="icon-set">

					<img src="/assets/images/car.svg" className="car" alt="car" />
						<span className="icon-text">Eastern Car Park</span>
					</div>
					</span>
					<span className="icon-with-text">
					<div className="icon-set">
					
					<img src="/assets/images/taxi.svg" className="taxi" alt="taxi" />
						<span className="icon-text">
							Taxi Rank/<br />Rideshare
						</span>
						</div>
					</span>
				</div>
			</div>
		);
	}
}

export default MCGScreen;
