import React from 'react'; 
import UtilityService from '../../services/UtilityService';


const timeDistanceInfo = (props) => {

    let icons = props.icons.map((icon,id) => {
        return (
            <img key={id} src={`/assets/images/${icon.fileName}`} className={icon.css} alt={icon.alt} />
        )
    })

    let unitMeasurement = props.distance >= 1000 ? 'km' : 'm';
    let distanceText = props.distance >= 1000 ? parseFloat(props.distance / 1000).toFixed(1) : props.distance;
    let time = UtilityService.calculateTimeToArrive(props.distance, props.exitDistance);

    let timeMeasurement = "min";
    if (time > 60)
    {
        time = parseFloat(time / 60, 1).toFixed(1);
        timeMeasurement = "hr";
    }

    return (
            <div className={`time-distance-access ${props.outerCss ? props.outerCss : ""}`}>
                <div className="time-distance-access-label">
                {
                    time > 0 && (
                        <div className="time-label">
                            {time} {timeMeasurement}
                        </div>
                    )
                }
                {
                    props.distance > 0 && (
                        <div className="distance-label">
                            {distanceText}{unitMeasurement}
                        </div>
                    )
                }
                </div>
                <div className="time-distance-access-icons">
                    {icons}
                </div>
            </div>
    ); 
}

export default timeDistanceInfo; 