import React from "react";
import posed from "react-pose";

const badge = React.forwardRef((props, ref) => {
	let badgeAdditionalCss = !props.hasBorder ? "no-border" : "";
	let icons = props.icons
		? props.icons.map((icon, id) => {
				return (
					<img
						key={id}
						src={`/assets/images/${icon.fileName}`}
						className={icon.css}
						alt={icon.alt}
					/>
				);
		  })
		: [];
	let rightTab =
		props.hasIcons && icons.length > 0 ? (
			<div
				className={`right-tab ${props.iconCss ? props.iconCss : ""} ${
					props.activeInnerCss ? props.activeInnerCss : ""
				}`}
			>
				{icons}
			</div>
		) : null;
	let opacity = props.addOpacity ? "opacity" : "";

	return (
		<div
			className={`badge ${badgeAdditionalCss} ${opacity}  ${
				props.activeOuterCss ? props.activeOuterCss : ""
			}`}
			ref={ref}
			style={props.style}
		>
			<div className={`left-tab ${props.textCss ? props.textCss : ""}`}>
				{props.children}
			</div>
			{rightTab}
		</div>
	);
});

const animatedBadge = posed(badge)({
	enter: {
		opacity: 1,
		y: 0,
		transition: { duration: 400 }
	},
	exit: {
		opacity: 0,
		y: "100%",
		transition: { duration: 200 }
	},
	flip: {
		scale: 1,
		transition: {
			duration: 300
		}
	}
});

export default animatedBadge;
