import moment from "moment";
const DEV = "development";

const REACT_APP_WALKING_SPEED_PERCH_TO_EXIT = parseInt(
	process.env.REACT_APP_WALKING_SPEED_PERCH_TO_EXIT
);

const REACT_APP_WALKING_SPEED_EXIT_TO_LANDMARK = parseInt(
	process.env.REACT_APP_WALKING_SPEED_EXIT_TO_LANDMARK
);

const REACT_APP_WALKING_BUFFER_MINUTES = parseInt(
	process.env.REACT_APP_WALKING_BUFFER_MINUTES
);

class UtilityService {
	isDev = () => {
		return process.env.NODE_ENV === DEV;
	};

	calculateSecondsDifference = (startTime, endTime) => {
		if (!moment.isMoment(startTime)) {
			startTime = moment(startTime);
		}
		if (!moment.isMoment(endTime)) {
			endTime = moment(endTime);
		}
		let duration = moment.duration(endTime.diff(startTime));
		return Math.floor(duration.asSeconds());
	};

	convertToMinute = seconds => {
		seconds = seconds * 1;
		if (Number.isInteger(seconds)) {
			return Math.floor(seconds / 60);
		}
		return 0;
	};

	calculateTimeToArrive = (distanceToLandmark, distanceToExit) => {
		let time = distanceToExit / REACT_APP_WALKING_SPEED_PERCH_TO_EXIT; // walking speed from perch to exit

		if (distanceToLandmark > distanceToExit) {
			time +=
				(distanceToLandmark - distanceToExit) /
				REACT_APP_WALKING_SPEED_EXIT_TO_LANDMARK; // walking speed from exit to landmark
		}

		time = Math.ceil(time) + REACT_APP_WALKING_BUFFER_MINUTES;

		return time;
	};

	relativeTimeTo = timestamp => {
		var mins = moment(timestamp).diff(moment.now(), "minutes");

		if (mins > 59) {
			return moment(timestamp).format("h:mma");
		}

		if (mins < 1)
		{
			return "Now";
		}

		return mins + " min";
	};

	getCurrentTimeString = () => {
		return moment.utc().toISOString();
	};

	getCurrentTimeStringRelative = minutes => {
		return moment()
			.add(minutes, "minutes")
			.utc()
			.startOf("minute")
			.toISOString();
	};

	shuffle = array => {
		let currentIndex = array.length;
		let temporaryValue;
		let randomIndex = 0;
		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		return array;
	};

	singularOrPlural = (value, noun) => { 
		if (value == null)
			return null;

		if (value.includes("-"))
		{
			return noun + "s";
		}

		return noun;
	}
}

export default new UtilityService();
