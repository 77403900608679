import React, { Component } from 'react';
import './styles/css/index.css';
import MCGScreen from './screens/MCGScreen';
import NBPScreen from './screens/NBPScreen';
import LDScreen from './screens/LDScreen';
import RDScreen from './screens/RDScreen';
import ConfigurationScreen from './screens/ConfigurationScreen';
import { Route, Switch } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="app">
        <Switch>
          <Route exact path="/LD" component={LDScreen} />
          <Route exact path="/RD" component={RDScreen} />
          <Route exact path="/nbp/:id?" component={NBPScreen} />
          <Route path="/mcg/:id?" component={MCGScreen} />
          <Route path="*" component={ConfigurationScreen} />
        </Switch>
      </div>
    );
  }
}

export default App;
