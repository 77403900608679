/*
    This service contains the logic to poll the data using either already pulled data (static data) 
    and dynamic data. The processed data will then be returned back to the UI with a flag, indicating
    whether the state should be updated. 
*/
import moment from "moment";
import { INTERVAL_TYPE, ROUTE_TYPE } from "../constants";
import HttpService from "./HttpService";
import UtilityService from "./UtilityService";
import Service from "../models/Service";
import ReactAI from 'react-appinsights';

class PollingService {
	constructor() {
		this.currentDataset = {};
		this.apiParamsArray = [];
		this.pullingDynamicData = false;
		this.insightsStarted = false;
	}

	//register the api params to be invoked in fixed interval
	init = requests => {
		this.apiParamsArray = requests.slice(0);
	};

	//Mapping data retrieved from the backend server to the required format used by the views
	mappedData = (data, resultArray, routeType, dateFromUtcTram, dateFromUtcTrain) => {
		let currentTime = UtilityService.getCurrentTimeString();
		if (data && data.directions && data.departures) {
			let services;
			let minTimeDiff;
			for (let id in data.directions) {
				if (services === undefined || routeType === ROUTE_TYPE.TRAM) {
					services = [];
					minTimeDiff = Number.MAX_VALUE;
				} else {
					break;
				}

				for (let index in data.departures) {
					let departure = data.departures[index];

					id = id * 1;
					//If route type is train consolidate all departures
					//Else group the departures based on its direction
					if (
						(routeType === ROUTE_TYPE.TRAIN ||
							(Number.isInteger(id) &&
								departure.direction_id &&
								departure.direction_id === id)) &&
						(departure.estimated_departure_utc ||
							departure.scheduled_departure_utc)
					) {
						// use real time departure date if available, if not fallback to scheduled date
						let expectedDeparture = departure.estimated_departure_utc
							? departure.estimated_departure_utc
							: departure.scheduled_departure_utc;

						let airCon =
							data.runs[departure.run_id] &&
							data.runs[departure.run_id].vehicle_descriptor
								? data.runs[departure.run_id].vehicle_descriptor.air_conditioned
								: false;
						let lowFloor =
							data.runs[departure.run_id] &&
							data.runs[departure.run_id].vehicle_descriptor
								? data.runs[departure.run_id].vehicle_descriptor.low_floor
								: false;

						let timeDifference = UtilityService.calculateSecondsDifference(
							currentTime,
							expectedDeparture
						);
						
						if ((routeType === ROUTE_TYPE.TRAIN && moment(expectedDeparture).isAfter(dateFromUtcTrain)) || 
							(routeType === ROUTE_TYPE.TRAM && moment(expectedDeparture).isAfter(dateFromUtcTram))) { 

							let service = new Service(
								departure.run_id,
								routeType,
								departure.direction_id,
								data.directions[departure.direction_id].direction_name,
								lowFloor,
								airCon,
								departure.platform_number,
								expectedDeparture,
								timeDifference,
								timeDifference <= minTimeDiff,
								departure.estimated_departure_utc != null
							);

							minTimeDiff =
								timeDifference <= minTimeDiff ? timeDifference : minTimeDiff;

							services.push(service);
						}
					}
				}

				if (services.length > 0) {
					if (routeType === ROUTE_TYPE.TRAIN) {
						resultArray[0] = services;
					} else {
						resultArray[id] = services;
					}
				}
			}
		}

		return resultArray;
	};

	//contains the business logic to retrieve the data from backend server
	getDataFromAPI = (dateFromUtcTram, dateFromUtcTrain) => {
		let services = {};
		let requests = [];
		let responses = {};
		for (let i = 0; i < this.apiParamsArray.length; i++) {
			let params = this.apiParamsArray[i];
			let dateFromUtc = params.mode == ROUTE_TYPE.TRAM ? dateFromUtcTram : dateFromUtcTrain;
			var rounded = moment(dateFromUtc).startOf("minute");

			params["date_utc"] = rounded.toISOString();

			let request = HttpService.getData(params).then(response => {
				if (!response.status) {
					return null;
				}
				
				let instrumentationKey = response.headers.get("x-instrumentation-key");

				if (!this.insightsStarted && instrumentationKey) { 
					ReactAI.init({
						instrumentationKey:instrumentationKey
					});

					this.insightsStarted = true;

					console.log("Azure Application Insights started with key", instrumentationKey);
				}
				
				this.mappedData(response.data, services, params.mode, dateFromUtcTram, dateFromUtcTrain);
				responses = Object.assign(services, responses);
			});
			requests.push(request);
		}

		return Promise.all(requests).then(() => {
			return Promise.resolve(responses);
		});
	};

	//contains the business logic to update time difference for the already retrieved data
	getDataFromCurrentData = (dateFromUtcTram, dateFromUtcTrain) => {
		let newDataset = Object.assign({}, this.currentDataset);
		let itemsIndexToBeRemoved = [];
		for (let list in newDataset) {
			let services = newDataset[list];
			for (let i = 0; i < services.length; i++) {
				let service = services[i];
				let routeType = service.routeType;
				let expectedDeparture = service.estimatedScheduledTime;
				
				if ((routeType === ROUTE_TYPE.TRAIN && moment(dateFromUtcTrain).isAfter(expectedDeparture)) || 
				(routeType === ROUTE_TYPE.TRAM && moment(dateFromUtcTram).isAfter(expectedDeparture))) { 
					itemsIndexToBeRemoved.push(i);
				}
			}

			for (let i in itemsIndexToBeRemoved) {
				services.splice(i, 1);
			}
		}
		return Promise.resolve(newDataset);
	};

	//route to the difference business logic based on type of update
	routeBasedOnInterval = (intervalType, dateFromUtcTram, dateFromUtcTrain) => {
		switch (intervalType) {
			case INTERVAL_TYPE.DYNAMIC:
				this.pullingDynamicData = true;
				return this.getDataFromAPI(dateFromUtcTram, dateFromUtcTrain);
			case INTERVAL_TYPE.STATIC:
				//Allow dynamic call to be finished else skip this static update
				if (this.pullingDynamicData) {
					return Promise.resolve(this.currentDataset);
				}
				return this.getDataFromCurrentData(dateFromUtcTram, dateFromUtcTrain); // threshold for removing services past walking distance

			default:
				return Promise.resolve();
		}
	};

	//the entry point from the view
	poll = pollingRequest => {
		return this.routeBasedOnInterval(
			pollingRequest.interval,
			pollingRequest.dateFromUtcTram,
			pollingRequest.dateFromUtcTrain
		).then(response => {
			if (response) {
				let hasUpdate = response && Object.keys(response).length > 0;

				if (hasUpdate && pollingRequest.interval === INTERVAL_TYPE.DYNAMIC) {
					this.currentDataset = Object.assign(this.currentDataset, response);
				} else {
					this.currentDataset = response;
				}

				if (this.pullingDynamicData) {
					this.pullingDynamicData = false;
				}

				return {
					data: this.currentDataset,
					hasUpdate: hasUpdate
				};
			}
			return {
				data: null,
				hasUpdate: false
			};
		});
	};

	processData = updatedData => {
		if (updatedData) {
			return true;
		}
		return false;
	};
}

export default new PollingService();
